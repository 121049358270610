Indice = (function () {
    const loaderUpdatePrices = document.querySelector('#loader-updatePrice');
    const soundCrashBoursier = document.querySelector('#crashBoursierAudio');
    const loaderBarOpen = document.querySelector('#loader-barOpen');
    const loaderBarClose = document.querySelector('#loader-barClose');
    const main = document.querySelector('main');
    const nameListePrix = document.querySelector('#nameListePrix');
    const timerElement = document.querySelector('#timer');
    const pubContainer = document.querySelector('#pubContainer');
    let intervalTempsRestant;
    let isCountdownRunning = false;

    function stopCountdown() {
        clearInterval(intervalTempsRestant);
    }

    const timer = (value) => {
        if (isCountdownRunning) {
            stopCountdown();
        }
        let tempsRestant = value;
        isCountdownRunning = true;
        intervalTempsRestant = setInterval(function() {
            // Décrémenter le temps restant
            tempsRestant--;
            
            // Mettre à jour l'affichage du décompte
            if(tempsRestant <= 6){
                loaderUpdatePrices.classList.remove('hidden');
            }
            if (tempsRestant <= 30) {
                timerElement.classList.add('animation');
            }else{
                timerElement.classList.remove('animation');
            }
            timerElement.innerText = tempsRestant;
            if(tempsRestant<=0){
                if (isCountdownRunning) {
                    stopCountdown();
                }
            }
           
        }, 1000);

    }
    // Set times for time remaining
    const setTimes = (times) => {
        timer(times);
    }

	const getPricesProduits = async (defaultListe = false) => {
        // defaultListe param for get the defaultListe or not 
		const response = await fetch(Routing.generate("api_prix", {
            defaultListe: defaultListe
        }), {
			method: "GET",
		})
        if (response.ok) {
            const data = await response.json();
            return data;
        }else{
            return {
                success: false,
                status: "error",
                message: "Une erreur est survenue",
            }
        }
	}
    // Mise a jour des prix des produits, si data est null on récupère les prix des produits avant de les mettre a jour
	const updatePrices = async (data = null) => {
        try {
            
            // Si data est null on récupère les prix des produits
            if (data === null) {
                let response = await getPricesProduits();
                
                if (response.success) {
                    
                    data = response.data;
                }else{
                    showError(response);
                    return;
                }
            }
		    // Mise a jour des prix
			replaceListePrix(data.prices, data.nom, data.times)
            loaderUpdatePrices.classList.add('hidden');
        } catch (error) {
			showError(error)
        }
	}
    // Remplacer les prix des produits
    const replaceListePrix = (prices, nom, times) => {
        i = 1;
        prices.forEach((price) => {
            const priceElement = document.querySelector(`[data-idEasyShop="${price.item}"].js-prix`)
            if (priceElement) {
                // On récupère le type de produit
                let type = priceElement.dataset.type;
                // Si de type simple
                if (type === "VARIATION") {
                    // Si c'est un variant on récupère l'id du produit parent
                    idItem = priceElement.getAttribute('data-produit-idEasyShop');
                }else{
                    idItem = price.item
                }
                const produit = document.querySelector(`[data-idEasyShop="${idItem}"].js-produit`);
                const prixVariation = priceElement.querySelector('.js-prix-variation');
                const prixContainer = priceElement.querySelector('.js-prix-container');
                // On vient mettre a jour le prix
                oldPrice = priceElement.innerText;
                newPrice = price.price;
                produit.classList.remove('up');
                produit.classList.remove('down');
                produit.classList.remove('constant');
                produit.classList.remove('show');
                prixVariation.innerHTML = '';
                // On met a jour l'icon du produit
                // si nouveau prix est plus grand que l'ancien on met la class up sinon down
                if (oldPrice != '-' && oldPrice != null) {
                    newPrice = parseFloat(newPrice);
                    oldPrice = parseFloat(oldPrice);
                    let variation = newPrice - oldPrice;
                    variation = variation.toFixed(1);
                    prixVariation.innerHTML = variation;
                    if (newPrice == oldPrice) {
                        produit.classList.add('constant');
                        prixVariation.innerHTML = '+ 0.0';
                    }else if (newPrice > oldPrice) {
                        produit.classList.add('up');
                    // si nouveau prix est plus petit que l'ancien on met la class down sinon up
                    }else if(newPrice < oldPrice){
                        produit.classList.add('down');
                    }else{
                        produit.classList.add('constant');
                        prixVariation.innerHTML = '+ 0.0';
                    }
                }else{
                    produit.classList.add('constant');
                }
                // on arrondie la variation à 0.1
                prixContainer.innerHTML = newPrice;
                // js-prix-variation
                // js-prix-container
                nbPosition = produit.dataset.position;
                nbPosition = parseInt(nbPosition) > 0 ? parseInt(nbPosition) : 0;
                delai = 100 + (nbPosition * 100);
                setTimeout(() => {
                    produit.classList.add('show');
                }, delai);

                // On retire la class show sur les produit constant au bout de 10 secondes
                setTimeout(() => {
                    if (produit.classList.contains('constant')) {
                        produit.classList.remove('show');
                        prixVariation.innerHTML = '';
                    }
                }, 10000);
                i++;
            }
        })
        
        nameListePrix.innerHTML = nom;
        //Times for time remaining
        setTimes(parseFloat(times));
    }
    
    // Afficher les erreurs
	const showError = (response = []) => {
		if (response.status) {
			switch (response.status) {
				case "BAR_CLOSE":
					// Le bar est fermé
					barClose()
					break
				default:
					console.log(response)
					break
			}
		} else {
            console.log(response);
			Notifications.Toast.fire({
				title: "Une erreur est survenue",
				icon: "error",
				background: "var(--color-danger)",
				color: "#fff",
				iconColor: "#fff",
			})
		}
	}
    // Lancement du crash boursier
	const crashBoursier = async(data) => {
		// Lancement du crash boursier
		// const response = await getPricesProduits();
        // Si tout est ok on met a jour les prix des produits
        replaceListePrix(data.prices, data.nom, data.times)
        loaderUpdatePrices.classList.add('hidden');
        // On lance l'animation
        main.classList.add('isCrashBoursier');
        main.classList.add('play');

        // Times doit etre exprimé en millisecondes
        let times = data.times * 1000;
        setTimeout(() => {
            stopCrashBoursier();
        },  times);

        toggleAnimationCrashBoursier(true);

        // Au bout de 5 secondes on enlève l'animation
        setTimeout(() => {
            toggleAnimationCrashBoursier(false);
        }, times);
	}
    // Stop animation crash boursier
    const stopCrashBoursier = async() => {
        main.classList.remove('isCrashBoursier');
        main.classList.remove('play');

        //Aller récupérer les prix des produits de la liste par default
        const response = await getPricesProduits(true);
        if (response.success) {
            data = response.data;
            updatePrices(data);
            // replaceListePrix(data.prices, data.nom, data.times)
        }else{
            showError(response);
        }
    }
    // Toggle animation crash boursier 
    const toggleAnimationCrashBoursier = (bool) => {
        if (bool) {
            //On reset le time du son
            soundCrashBoursier.currentTime = 0;
            // main.classList.add('play');
            soundCrashBoursier.play();
            
        }else{
            // main.classList.remove('play');
            soundCrashBoursier.pause();
        }
	}
    // Si le bar est ouvert
	const barOpen = () => {
		// Ouverture du bar
		// On affiche une notification comme quoi le bar est ouvert
        // On retire la class hidden pour le bar close
        loaderBarClose.classList.add('hidden');
	}
    // Si le bar est fermé
	const barClose = () => {
		// Fermeture du bar
		// On affiche une notification comme quoi le bar est fermé
        loaderBarClose.classList.remove('hidden');
	}
    // Vérifier si le bar est ouvert
    const isOpen = async () => {
        const response = await fetch(Routing.generate("api_isOpen", {}), {
            method: "GET",
        })
        if (response.ok) {
            const data = await response.json()
            return data.success;
        } else {
            return false
        }
    }
    const isStaticPrice = async (bool) => {
        // SI c'est l'affichage de liste de prix staticPrice, on cache les loaderBarClose,le timer 
        if(bool){
            timerElement.classList.add('hidden');
            loaderBarClose.classList.add('hidden');
            loaderBarOpen.classList.add('hidden');
            loaderUpdatePrices.classList.add('hidden');
        }
    }
    const showPub = (data) => {
        const name = data.name;
        const urlPub = data.url;
        const timeLeft = data.times ?? 10;

        if (!pubContainer.classList.contains('show') || name != null || urlPub != null || timeLeft != null) {
            // On set l'url de l'image de la pub
            img = pubContainer.querySelector('img');
            // On génère une url
            // ex URLImg = /pub/demo.jpg
            //ex : https://www.domaine.com/pub/demo.jpg

            // On vérifie si l'url est relative ou absolue
            if (!urlPub.startsWith('http')) {
                // Si l'url est absolue on ne fait rien
                img.src = window.location.origin + !urlPub.startsWith('/img/') ? '/img/' + urlPub : urlPub;
            }else{
                img.src = urlPub;
            }
            img.alt = name;

            // On affiche la pub au bout de 2 secondes, le temps que l'image soit chargée
            setTimeout(() => {
                // On affiche la pub
                pubContainer.classList.remove('hide');
                pubContainer.classList.remove('hidden');
                pubContainer.classList.add('show');

                let duration = timeLeft * 1000;

                // On set le temps d'affichage de la pub
                const pubTimesLeft = pubContainer.querySelector('#pubTimeLeft .pubTimeLeft-bar');
                pubTimesLeft.style.transitionDuration = duration + 'ms';

                // On cache la pub au bout de 10 secondes
                setTimeout(() => {
                    // On cache la pub, on hide le container
                    pubContainer.addEventListener('transitionend', () => {
                        pubContainer.classList.add('hidden');
                    }, {once: true});
                    pubContainer.classList.remove('show');
                    pubContainer.classList.add('hide');
                }, duration);
            }, 2000);
        }
    }

	return {
		updatePrices: updatePrices,
		crashBoursier: crashBoursier,
		barOpen: barOpen,
		barClose: barClose,
		showError: showError,
        isOpen: isOpen,
        isStaticPrice: isStaticPrice,
        showPub: showPub,
        setTimes: setTimes,
	}
})()

const staticPrice = document.querySelector('#nameListePrix').getAttribute('data-static-price');

if (staticPrice == 'true') {
    // Si c'est des prix static on ne se base pas sur les events
    Indice.isStaticPrice(true);
}else{
    // On se connecte au serveur de socket
    const getInfosWebsocket = async () => {
        const response = await fetch(Routing.generate("api_websocket", {}), {
            method: "GET",
        })
        
        if (response.ok) {
            const data = await response.json()
            return data;
        } else {
            return {
                success: false,  
            }
        }
    }
    getInfosWebsocket().then((data) => {
        if (data.success) {
            // console.log(data);
            const apiKey = data.key;
            const clusterId = data.clusterId;
            const roomId = data.roomId;
            var pieSocket = new PieSocket({
                clusterId: clusterId,
                apiKey: apiKey,
                notifySelf: true,
            })
        
            pieSocket.subscribe(roomId).then((channel) => {
                channel.on("message", async (msg) => {
                    try {
                        const json = JSON.parse(msg.data)
                        // const json = await data.json();
                        // const msg = JSON.parse(data);
                        console.log(json);
                        switch (json.event) {
                            case "CRASH_BOURSIER":
                                // On lance le crash boursier
                                Indice.crashBoursier(json.data)
                                break
                            case "PRICE_UPDATED":
                                Indice.updatePrices(json.data)
                                break
                            case "BAR_OPEN":
                                Indice.barOpen()
                                break
                            case "BAR_CLOSE":
                                Indice.barClose()
                                break
                            case "RELOAD":
                                // Si on reçoit un reload on recharge la page
                                // Force reload without cache
                                window.location.reload(true)
                                break
                            case "PUB_PUBLISH":
                                // Afficher une pub
                                Indice.showPub(json.data)
                                break
                            case "TIME_UPDATE":
                                // On set le temps restant
                                timeLeft = json.data ? json.data.times ? json.data.times : null: null;
                                if (timeLeft) {
                                    Indice.setTimes(timeLeft)
                                }
                                break
                            default:
                                // Default ne rien faire
                                break
                        }
                    } catch (error) {
                        Indice.showError(error)
                    }
                    // On décode les données
                })
            })
        }else{
            Indice.showError(data);
        }
    });

    // On vériifie si le bar est ouvert, si oui on récupère les prix des produits
    Indice.isOpen().then((isOpen) => {
        if (isOpen) {
            Indice.updatePrices()
        } else {
            Indice.barClose()
        }
    })
}

// Définition du slider, si il existe
const sliderPrices = document.querySelector("#slider-prices")
if (sliderPrices) {
    const slider = new Splide("#slider-prices", {
        perPage: 5,
        perMove: 1,
        rewind: true,
        autoplay: true,
        interval: 5000,
        pauseOnHover: false,
        pauseOnFocus: false,
        pagination: false,
        arrows: false,
        drag: false,
        gap: "2.5rem",
        breakpoints: {
            1280: {
                destroy: true,
            },
        },
    }).mount();

    // Faire une pause dans le slider au moment ou on arrive à la fin et au debut
    slider.on('moved', function (newIndex) {
        lastSlide = slider.length - 1;
        const { Autoplay } = slider.Components;
        lastSlideElement = slider.Components.Elements.slides[lastSlide];
        // On vérifie si la last slide est visible ou non 
        // on divise le nombre de slide par le nombre visible
        if (lastSlideElement.classList.contains('is-visible')) {
            // Arrête l'autoplay quand on atteint la dernière slide
            // slider.pause();
            Autoplay.pause();

            // Attend 10 secondes puis redémarre l'autoplay
            //Puis revenir au début 
            setTimeout(function () {
                slider.go(0);
                // Attend 10 seconde puis redémarre l'autoplay
            }, 8000); // Temps d'attente avant de redémarrer (en millisecondes)

            setTimeout(function () {
                // slider.play();
                Autoplay.play();
            }, 12000); // Temps d'attente avant de redémarrer (en millisecondes)
        }
    });
}


// Définition du slider tab (pour les categories)
const sliderTab = document.querySelector("#slider-tabs")
if (sliderTab) {
    const sliderTab = new Splide("#slider-tabs", {
        rewind: false,
        isNavigation: true,
        autoWidth: true,
        clones: 0,
        autoplay: false,
        pagination: false,
        arrows: false,
        drag: true,
    }).mount();

    const btnTabs = document.querySelectorAll('button.js-tab');

    btnTabs.forEach((btnTab) => {
        btnTab.addEventListener('click', (e) => {
            e.preventDefault();
            const idEasyShop = btnTab.getAttribute('aria-controls');
            btnActives = document.querySelectorAll('button.js-tab.is-active');
            btnActives.forEach((btnActive) => {
                btnActive.classList.remove('is-active');
            });
            btnTab.classList.add('is-active');
            if (idEasyShop) {
                const container = document.querySelector(`[data-id-tab="${idEasyShop}"]`);
                if (container) {
                    const positionButton = btnTab.getBoundingClientRect();
                    const positionTop = container.offsetTop;
                    // On récupère la position du container, et on scroll pour afficher le container juste en dessous du bouton
                    // Si la position est négative on scroll
                    window.scrollTo({
                        top: positionTop - positionButton.bottom - 10,
                        behavior: 'smooth'
                    });
                }
            }
        })
    });
}